import React, { Suspense, useState, useRef } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import DeleteIcon from "@mui/icons-material/Delete"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { Canvas, useThree, useFrame } from "@react-three/fiber";
import { View } from "@react-three/drei";

import { getStripeCheckoutURL } from "../api"; 


import { ScreenshotForProjectsPage } from "../components/Screenshot";

import { Link } from "react-router-dom";

import { useObjectStore } from "../zustand/objects";

import { useAccountStore } from "../zustand/account";
import { Card, Button,  Image, Input, Text, Group, Badge, ActionIcon, Tooltip } from '@mantine/core';
import { RED_GREEN_RGTC2_Format } from 'three';


export default function ProjectsTable({ projects, isGuest, handleCopySubmit }) {

    const { getSpecificUserProject, unloadObjects, previewCameraPosition, objectsAreLoaded, projectName, artboards, storyboardFields, screenshots, floorColour } = useObjectStore();
    const { updateDeleteModalOpen, updateDeleteID, createNewProject, userStatus } = useAccountStore();




    const setProjectState = (projectKey) => {
        getSpecificUserProject(projectKey)
    }

    const handleDeleteClick = (id) => {
        updateDeleteID(id)
        updateDeleteModalOpen()
    }

    const dateConvert = (timestamp) => {
        return new Date(timestamp)
    }


    const [displayForm, setDisplayForm] = useState(true)



    const onEditClick = (e) => {
        unloadObjects()
        setProjectState(e)
    }


    // NEED TO MAKE NEW XANO FUNCTION THAT SENDS THE PROJECT KEY TO THE NEW ARRAY AND THEN THIS IS USED TO FIND LINKED PROJECTS

  const handleSubscriptionClick = (label) => {

    getStripeCheckoutURL({
        success_url: 'https://mbxr.io/projects',
        cancel_url: 'https://mbxr.io/logging-out',
        // success_url: 'http://localhost:3000/projects',
        // cancel_url: 'http://localhost:3000/membership',
        subscription: label
    })
    // .then((res) => console.log(res.url))
    .then((res) => window.location.replace(res.url))}

    const canvasRef = useRef()

    async function sendProject(e) {
        let projectsLength = projects.length
        createNewProject({ name: `Project ${projectsLength += 1}` })
    }


    return (<div   >
       
        <Suspense fallback={null}>
            <CssBaseline />
            <Suspense fallback={<div>Loading... </div>}>
                <Box>
                    <Container component="form" style={{ margin: 0, padding: 0 }}>
                        <Grid sx={{ marginTop: 0 }} container  id="container">
                        
                            {projects && projects.map((row, i) => (
                               row && objectsAreLoaded && <Suspense fallback={<img src="/image/loading.gif" style={{width: "50px", height: "50px"}}/>} key={i}>
                                    <Grid item key={i} xs={12} sm={6} md={3}>
                                        <Card radius={16} padding={0} style={{margin:10}}
                                        shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)">
                                        {/* <Card sx={{ display: 'flex', flexDirection: 'column', borderRadius: 0, marginRight: "12px", marginBottom: "12px", borderWidth: 0.5, borderStyle: "solid", borderColor: "black"  }}> */}
                                            <Box style={{
                                                top: 0,
                                                left: 0,
                                                width: "250", 
                                                height: "150",
                                                overflow:"hidden"
                                            }}>
                                                <Suspense fallback={<img src="/image/loading.gif" style={{width: "50px", height: "50px"}}/>}>
                                                {/* <View
                                                style={{ position: 'relative', overflow: 'hidden' }}
                                                index={i}
                                                className={`view${i}`}> */}
                                               {objectsAreLoaded && <ScreenshotForProjectsPage index={i} floorColour={row.floorColour} artboard={row.artboards[1]} />}

                                                    
                                                {/* </View> */}
                                                </Suspense>
                                            </Box>
                                            <Box>
                                                <Box style={{ width: "100%", margin:10 }}>
                                                    <Text size={'sm'} fw={600}>
                                                        {row.projectName}
                                                    </Text>
                                                </Box>
                                                <Box style={{ width: "100%", margin:10 }}>
                                                <Text size={'sm'} fw={400}>
                                                        {new Date(row.created_at).toDateString()}
                                                    </Text>
                                                </Box>
                                            </Box>
                                            <Group>
                                            {(!isGuest && userStatus === 'loggedIn-none' || userStatus === 'loggedIn-canceled') && i !== 0 ?                               
                                            <Button onClick={() => handleSubscriptionClick("pioneer")}  style={{
                                                width:'60%', margin:10, inline: true, backgroundColor: 'gray', borderRadius: 32}} ml={10}>Upgrade</Button >
                                            :
                                                <Button
                                                    style={{
                                                        width:'60%', margin:10, inline: true, backgroundColor: 'black', borderRadius: 32}}
                                                        onClick={() => { onEditClick(row.projectKey) }}
                                                        component='a'
                                                        href={`/editor/${row.projectKey}`}>
                                                            Edit
                                                            </Button>}
                                                      { !isGuest && <Tooltip label="Delete project">
                                                        <ActionIcon
                                                        variant='transparent'
                                                        color='black'
                                                        
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            handleDeleteClick(row.projectKey)
                                                        }}>
                                                            <DeleteIcon/>
                                                        </ActionIcon></Tooltip>}
                                                        <Tooltip label="Duplicate project"><ActionIcon 
                                                        variant='transparent'
                                                        color='black'
                                                        
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            // console.log(row.projectKey)
                                                            handleCopySubmit(row.projectKey)
                                                        }}>
                                                            <ContentCopyIcon/>
                                                        </ActionIcon></Tooltip>
                                            </Group>
                                        </Card>
                                    </Grid></Suspense>
                            ))}

                          

                            {/* </Html> */}
                            {/* </Canvas> */}
                        </Grid>
                    </Container>
                </Box>
            </Suspense>
            </Suspense>
        <Canvas
            eventSource={document.getElementById("root")}
            className="canvas"
            shadows
            style={{  position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, overflow: 'hidden' }}
            camera={{
                position: [0, 0, 0],
                // position: [previewCameraPosition[0], 1.85, previewCameraPosition[2] + 2],
                fov: 55
            }} 
            frameloop="demand"
            gl={{ preserveDrawingBuffer: true }}
            ref={canvasRef}
        >
            <View.Port />
        </Canvas>
    </div>
    );
}



