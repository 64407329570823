import React, {useState} from 'react';
import { Button } from '@mantine/core';
import { useObjectStore } from "../zustand/objects";
import TableViewIcon from '@mui/icons-material/TableView';
import RefreshIcon from '@mui/icons-material/Refresh';
import IosShareIcon from '@mui/icons-material/IosShare';
import LinkIcon from '@mui/icons-material/Link';
import ViewCozyIcon from '@mui/icons-material/ViewCozy';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { ActionIcon, Tooltip } from '@mantine/core';



const copyDynamicTableToClipboard = (screenshots, artboards, storyboardFields) => {
  const chunkObject = (obj, size) => {
      const chunks = [];
      const keys = Object.keys(obj);
      for (let i = 0; i < keys.length; i += size) {
          const chunk = {};
          keys.slice(i, i + size).forEach(key => {
              chunk[key] = obj[key];
          });
          chunks.push(chunk);
      }
      return chunks;
  };

  // Chunk the data into smaller groups of up to 4
  const screenshotChunks = chunkObject(screenshots, 4);
  const artboardChunks = chunkObject(artboards, 4);
  const storyboardFieldChunks = chunkObject(storyboardFields, 4);

  // Dynamically generate HTML for the tables
  const tableHTML = screenshotChunks.map((chunk, index) => `
    <table border="1" style="border-collapse: collapse; table-layout: fixed; width: auto; margin-bottom: 16px; text-align: center; display: inline-table;">
  <tbody>
    <tr>
      ${Object.keys(chunk).map(id => `
        <td style="width: 150px; padding: 0px;">
          <img src="${chunk[id]}" alt="Screenshot ${id}" style="width: 144px; height: 100px;" />
        </td>
      `).join('')}
    </tr>
    <tr>
      ${Object.keys(storyboardFieldChunks[index] || {}).map(id => `
        <td style="width: 150px; padding: 0px;"><b>${id}: ${storyboardFieldChunks[index][id].name}</b></td>
      `).join('')}
    </tr>
    <tr>
      ${Object.keys(storyboardFieldChunks[index] || {}).map(id => `
        <td style="width: 150px; padding: 0px;">${storyboardFieldChunks[index][id].description}</td>
      `).join('')}
    </tr>
    <tr>
      ${Object.keys(artboardChunks[index] || {}).map(id => `
        <td style="width: 150px; padding: 0px;">
          <a target="_blank" href="https://mbxr.io/share/5bb83?artboard=${id}">View preview</a>
        </td>
      `).join('')}
    </tr>
  </tbody>
</table>`).join('');

  // Copy the table to the clipboard as HTML
  const blob = new Blob([tableHTML], { type: 'text/html' });
  const clipboardItem = new ClipboardItem({ 'text/html': blob });

  navigator.clipboard.write([clipboardItem])
      .catch(err => {
          console.error('Failed to copy the table:', err);
          alert('Failed to copy the storyboard table to the clipboard.');
      });
};


// React Component Example
export const CopyStoryboardTable = () => {
  const { screenshots, artboards, storyboardFields } = useObjectStore();

  const handleCopy = () => {
      copyDynamicTableToClipboard(screenshots, artboards, storyboardFields);
  };

  const [hover, setHover] = useState(false)

  return (                  
                  <Tooltip label="Copy as a Table">
                  <ActionIcon
                    style={{ marginLeft: 10, height: 25 }}
                    size={"sm"}
                    variant='transparent'
                    color={!hover ? "#424242" : "#868e96"}
                    onPointerOver={() => setHover(true)}
                    onPointerLeave={() => setHover(false)}
                    onClick={() => handleCopy()}>
                    <TableViewIcon fontSize="medium" />
                  </ActionIcon>
                  </Tooltip>
  );
};

export const CopyPreviewEmbed = (projectKey) => {
  const copyIframeCode = (page) => {
    // Copy to clipboard
    const code = `<iframe src="https://mbxr.io/embed-${page}/${projectKey.projectKey}" width="900" height="400" style="border: none;"></iframe>`
    navigator.clipboard.writeText(code)
  }

  const [hover, setHover] = useState(false)

  return (

                  
    <Tooltip label="Copy Preview iframe">
    <ActionIcon
      style={{ marginLeft: 10, height: 25 }}
      size={"sm"}
      variant='transparent'
      color={!hover ? "#424242" : "#868e96"}
      onPointerOver={() => setHover(true)}
      onPointerLeave={() => setHover(false)}
      onClick={() => copyIframeCode('share')}>
      <SlideshowIcon fontSize="medium" />
    </ActionIcon>
    </Tooltip>
  );
};


export const CopyStoryboardEmbed = (projectKey) => {
  console.log(projectKey)
  const copyIframeCode = (page) => {
    // Copy to clipboard
    const code = `<iframe src="https://mbxr.io/embed-${page}/${projectKey.projectKey}" width="900" height="400" style="border: none;"></iframe>`
    navigator.clipboard.writeText(code)
  }

  const [hover, setHover] = useState(false)

  return (
                  <Tooltip label="Copy Storyboard iframe">
                  <ActionIcon
                    style={{ marginLeft: 10, height: 25 }}
                    size={"sm"}
                    variant='transparent'
                    color={!hover ? "#424242" : "#868e96"}
                    onPointerOver={() => setHover(true)}
                    onPointerLeave={() => setHover(false)}
                    onClick={() => copyIframeCode('story')}>
                    <ViewCozyIcon fontSize="medium" />
                  </ActionIcon>
                  </Tooltip>
  );
};



export const CopyPreviewLink = (projectKey) => {

  const [hover, setHover] = useState(false)

  return (
    <Tooltip label="Copy Preview Link">
    <ActionIcon
      style={{ marginLeft: 10, height: 25 }}
      size={"sm"}
      variant='transparent'
      color={!hover ? "#424242" : "#868e96"}
      onPointerOver={() => setHover(true)}
      onPointerLeave={() => setHover(false)}
      onClick={() => navigator.clipboard.writeText(`https://mbxr.io/story/${projectKey.projectKey}`)}>
      <LinkIcon fontSize="medium" />
    </ActionIcon>
    </Tooltip>
  );
};


