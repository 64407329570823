import { saveObjectUpdate, saveObjectAdd, saveObjectDelete, deleteSpecificAIModel, getSpecificAIModel, getSpecificUserProjectAPI, getSpecificUserProjectGuestAPI, saveUserProjectAPI, getUserAIModels, sendAIModelPrompt, updateFigmaFromID } from "../api";
import { create } from "zustand";
import { liveblocks } from "@liveblocks/zustand";
import { createClient } from "@liveblocks/client";
import produce from "immer";
import shortid from "shortid";
import * as THREE from "three";

import { XanoClient } from '@xano/js-sdk/lib/index.js'

const _ = require('lodash');

// ~~~~~~~~~~~~~~~~~~~~~~~~
// STATE
// ~~~~~~~~~~~~~~~~~~~~~~~~
export const useObjectStore = create((set) => ({
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // PROJECT STATE
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  xanoClient: new XanoClient({
    instanceBaseUrl: "https://x1hz-knoc-qcpv.e2.xano.io/",
    realtimeConnectionHash: "dFeg0QJY6dJOp0URAEnSIc4LRKU",
  }),
  uniqueRealtimeId: null,
  isError: false,
  screenshotStatus: false,
  isMovingVRObject: false,
  isMenuOpen: false,
  tabValue: "elements",
  isOtherPreviewOpen: false,
  isDraggingModel: false,
  presence: [],
  cursor: {},
  preview: {},
  headset: {},
  position: [0, 0, -5],
  rotation: [0, 0, 0, 0],
  joinChannel: (projectKey, val) => set(produce((state) => {
    state.uniqueRealtimeId = Math.ceil(Date.now() + Math.random())
    const updateObject = {
      updateType: "presenceUpdate",
      projectKey: projectKey,
      uniqueRealtimeId: state.uniqueRealtimeId,
      update: val,
      socketId: null
    } 
    state.sendPresenceUpdateToChannel(updateObject)
  })),
  addOrUpdatePresence: (val) => set(produce((state) => {
    const i = state.presence.findIndex(e => e.uniqueRealtimeId === val.payload.uniqueRealtimeId);
    // find index of matching unique ID, if there edit specific property in field param,
    // otherwise add new element to presence array
    if (i > -1) {state.presence[i].update[val.payload.field] = val.payload.update[val.field]} // (2)
    else {state.presence.push({
      update: val.payload.update,
      uniqueRealtimeId: val.payload.uniqueRealtimeId,
      socketId: val.client.socketId
    })}
  })),
  removePresence: (val) => set(produce((state) => {
    state.presence.splice(state.presence.findIndex((x) => x.socketId === val),1)
  })),
  setPosition: (val) => set(produce((state) => {
    state.position = val
  })),
  setRotation: (val) => set(produce((state) => {
    state.rotation = val
  })),
  projectName: "",
  projectUserName: "",
  projectUserActivity: "",
  projectUserOutcome: "",
  projectKey: null,
  exportData: [],
  exportDataCaptured: false,
  artboards: null,
  // artboards: {
  //   1: []
  // },
  screenshots: {
    1: ""
  },
  aiModels: [],
  prompts: [],
  storyboardFields: {
    1: {
      "description": "",
      "comments": "",
      "name": ""
    }
  },
  floorColour: "lightGrey",
  transformMode: "translate",
  storyboardPOV: {
    1: {
      "rotation": [0, 0, 0, 0],
      "position": [0, 0, 0]
    }
  },
  previewCameraPosition: [0, 0, 0],
  previewCameraRotation: [0, 0, 0],
  teleportCount: 0,
  screenshotTaken: false,
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // SESSION LOCAL STATES
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  cameraSelected: false,
  textModalOpen: false,
  shareModalOpen: false,
  userAccessModalOpen: false,
  addAIModalOpen: false,
  uploadModalOpen: false,
  imageModalOpen: false,
  searchModelsModalOpen: false,
  imagePurpose: null,
  editingID: '',
  undoActions: [],
  redoActions: [],
  undoing: false,
  editorMode: true,
  previewVR: true,
  shapeHovered: false,
  objectsAreLoaded: false,
  firstPreviewArtboardLoadDone: false,
  groupedObjects: [],
  grouping: false,
  dragging: false,
  teleporting: false,
  overTransformControls: false,
  editingObjectTitle: false,
  updateObjectTitleStatus: (val) => set(produce((state) => {
    state.editingObjectTitle = val
  })),
  setTabValue: (val) => set(produce((state) => {
    state.tabValue = val
  })),
  setIsOtherPreviewOpen: () => set(produce((state) => {
    state.isOtherPreviewOpen = !state.isOtherPreviewOpen
  })),
  setIsDraggingModel: (val) => set(produce((state) => {
    state.isDraggingModel = val
  })),
  setIsMovingVRObject: (val) => set(produce((state) => {
    state.isMovingVRObject = val
  })),
  setIsError: (val) => set(produce((state) => {
    state.isError = val
  })),
  setCameraSelected: (val) => set(produce((state) => {
    state.cameraSelected = val
  })),
  setCursor: (val) => set(produce((state) => {
    state.cursor = val
  })),
  setIsMenuOpen: (val) => set(produce((state) => {
    state.isMenuOpen = val
  })),
  setPreview: (val) => set(produce((state) => {
    state.preview = val
  })),
  setHeadset: (val) => set(produce((state) => {
    state.headset = val
  })),
  setOverTransformControls: (val) => set(produce((state) => {
    state.overTransformControls = val
  })),
  setGrouping: (val) => set(produce((state) => {
    state.grouping = val
    // state.groupedObjects = []
    state.selectedObjectID != 0 && state.groupedObjects.push(state.selectedObjectID)
    state.selectedObjectID = "0";
  })),
  setTeleporting: (val) => set(produce((state) => {
    state.teleporting = val
  })),
  setDragging: (val) => set(produce((state) => {
    state.dragging = val
  })),
  setTeleportCount: (val) => set(produce((state) => {
    state.teleportCount = val
  })),
  loadObjects: () => set(produce((state) => ({ objectsAreLoaded: true }))),
  setFirstPreviewArtboardLoadDone: (value) => set(produce((state) => {
    state.firstPreviewArtboardLoadDone = value
  })),
  setExportData: (value) => set(produce((state) => {
    state.exportData.push(value)
  })),
  setExportDataCaptured: (value) => set(produce((state) => {
    state.exportDataCaptured = value
  })),
  unloadObjects: () => set(produce(() => ({ objectsAreLoaded: false }))),
  switchTextModalOpen: () =>
    set(produce((state) => {
      state.textModalOpen = !state.textModalOpen;
    })),
  switchImageModalOpen: () =>
    set(produce((state) => {
      state.imageModalOpen = !state.imageModalOpen;
    })),
  switchsearchModelsModalOpen: () =>
    set(produce((state) => {
      state.searchModelsModalOpen = !state.searchModelsModalOpen;
    })),
  switchShareModalOpen: () =>
    set(produce((state) => {
      state.shareModalOpen = !state.shareModalOpen;
    })),
  switchUserAccessModalOpen: () =>
    set(produce((state) => {
      state.userAccessModalOpen = !state.userAccessModalOpen;
    })),
  switchAddAIModalOpen: () =>
    set(produce((state) => {
      state.addAIModalOpen = !state.addAIModalOpen;
    })),
  switchUploadModalOpen: () =>
    set(produce((state) => {
      state.uploadModalOpen = !state.uploadModalOpen;
    })),
  switchVRMode: () =>
    set(produce((state) => {
      state.previewVR = !state.previewVR;
    })),
  switchModes: () =>
    set(produce((state) => {
      state.editorMode = !state.editorMode;
    })),
  setScreenshotTaken: (value) =>
    set(produce((state) => {
      state.screenshotTaken = value
    })),
  setImagePurpose: (value) =>
    set(produce((state) => {
      state.imagePurpose = value
    })),
  setEditingID: (value) =>
    set(produce((state) => {
      state.editingID = value
    })),
  currentObjectArtboard: "1",
  // currentObjectArtboard: "1",
  selectedObjectID: "0",
  updateObjectSelected: (selectedValue) =>
    set(produce((state) => {
      state.selectedObjectID = selectedValue;
    })),
  shapeHoverChange: (value) =>
    set(produce((state) => {
      state.shapeHovered = value
    })),
  addToGroupedObjects: (id) =>
    set(produce((state) => {
      state.groupedObjects.push(id)
    })),
  removeFromGroupedObjects: (id) =>
    set(produce((state) => {
      state.groupedObjects = state.groupedObjects.filter(function (item) {
        return item !== id
      })
    })),
  clearGroupedObjects: () =>
    set(produce((state) => {
      state.groupedObjects = []
      saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // SWITCH TRANSFORMCONTROLS TRANSFORM MODE
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  transformModeChange: ({ transformMode }) =>
    set(produce((state) => {
      state.transformMode = transformMode;
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  //  GET MODEL VIA PROMPT
  // ~~~~~~~~~~~~~~~~~~~~~~~~~
  setAIModelsByPrompts: (prompts) => prompts.map((mapped) => getSpecificAIModel(mapped)
    .then((res) =>
      set(produce((state) => { state.aiModels = [...state.aiModels, res] })))),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  //  REMOVE AIMODEL
  // ~~~~~~~~~~~~~~~~~~~~~~~~~
  removeAIModel: (modelId, projectKey) =>
    deleteSpecificAIModel(modelId).then(response =>
      getUserAIModels().then(response =>
        set(produce((state) => {
          state.aiModels = response
        })))),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  //  GET MODELS OWNED BY USER
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  getAIModels: () =>
    getUserAIModels().then(response =>
      set(produce((state) => {
        state.aiModels = response
      }))),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  //  SEND PROMPT AND GENERATE MODEL JSX, ADDING TO DATABASE
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  sendAIPrompt: (prompt, projectKey) =>
    sendAIModelPrompt({ prompt: prompt, projectKey: projectKey }),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // LOAD A SPECIFIC USER PROJECT 
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // COULD SPLIT THIS. GET ALL PROJECT LEVEL INFO
  // THEN RUN SUBSEQUENT API REQUESTS TO GET ARTBOARD DATA BY ARTBOARD
  // FOR THE LATTER, SEND A RESPONSE IN THE INITIAL RESPONSE WITH AN ARRAY OF ARTBOARD NUMBERS [1,2,3,4]
  // THEN FOREACH OVER THAT, REQUEST ARTBOARD DATA AND STORYBOARD DATA FOR EACH NUMBER,
  // ALSO USING THE NUMBER AS AN ARTBOARD NUMBER PARAM FOR THE REQUEST
  getSpecificUserProject: (projectKey) =>
    getSpecificUserProjectAPI(projectKey)
      .then(response =>
        set(produce((state) => {
          state.artboards = response.artboards
          state.storyboardFields = response.storyboardFields
          response.screenshots && response.screenshots.forEach((item, i) => {
            state.screenshots[i + 1] = item.url
          })
          state.projectName = response.projectName
          state.projectKey = projectKey
          state.objectsAreLoaded = true
          state.floorColour = response.floorColour
          state.prompts = response.prompts
        }))),
  // .then(() => set(produce((state) => {state.prompts.map((mapped) => {return getSpecificAIModel(mapped).then(res => {state.aiModels = [...state.aiModels, res]})})}))),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // LOAD A SPECIFIC USER PROJECT WITHOUT AUTH
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  getSpecificUserProjectGuest: (projectKey) =>
    getSpecificUserProjectGuestAPI(projectKey)
      .then(response =>
        set(produce((state) => {
          state.artboards = response.artboards
          state.storyboardFields = response.storyboardFields
          response.screenshots && response.screenshots.forEach((item, i) => {
            state.screenshots[i + 1] = item.url
          })
          state.projectName = response.projectName
          state.projectKey = projectKey
          state.objectsAreLoaded = true
          state.floorColour = response.floorColour
          state.prompts = response.prompts
        }))),
  // .then(() => set(produce((state) => {state.prompts.map((mapped) => {return getSpecificAIModel(mapped).then(res => {state.aiModels = [...state.aiModels, res]})})}))),

  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // SAVE USER PROJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  saveUserProject: () =>
    set(produce((state) => {

      saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })

    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // CLEAR PROJECT STATE
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  wipeProjectState: () =>
    set(produce((state) => {
      state.artboards = { 1: [{ "id": "camera", "scale": [1, 0, 1], "object": "camera", "content": false, "category": false, "position": [0, 0, 0], "rotation": [0, 0, 0, 0], "destination": false, "objectTitle": "camera", "matrixState": new THREE.Quaternion() }] }
      state.screenshots = { 1: "" }
      state.storyboardFields = { 1: { "description": "", "comments": "", "name": "" } }
      state.projectName = ''
      state.projectUserName = ''
      state.projectUserActivity = ''
      state.projectUserOutcome = ''
      state.projectKey = null
      state.firstPreviewArtboardLoadDone = false
      state.objectsAreLoaded = false
      state.floorColour = "lightGrey"
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // PROJECT ACTIONS
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // SEND TO UNDO/REDO ARRAY
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  arrangeUndoRedo: (val) =>
    set(produce((state) => {
      // console.log(val)
      // find data to be stored for undo/redo
      const updateType = val.updateType === "objectUpdate" ? val.updateType :
        val.updateType === "objectAdd" ? "objectRemove" :
          val.updateType === "objectRemove" ? "objectAdd" :
            val.updateType

      const data = (val.updateType === "objectUpdate") ?
        state.artboards[val.artboard].find(
          (x) => x.id === val.objectID
        )[val.field] : (val.updateType === "objectRemove") ?
          state.artboards[val.artboard].find(
            (x) => x.id === val.objectID
          )
          : null

      const artboards = val.updateType === "objectUpdate" ? null :
        val.updateType === "objectAdd" ? null :
          val.updateType === "objectRemove" ? null :
            state.artboards

      const screenshots = val.updateType === "objectUpdate" ? null :
        val.updateType === "objectAdd" ? null :
          val.updateType === "objectRemove" ? null :
            Object.values(state.screenshots)

      const storyboardFields = val.updateType === "objectUpdate" ? null :
        val.updateType === "objectAdd" ? null :
          val.updateType === "objectRemove" ? null :
            state.storyboardFields


      const currentState =
      { //  general fields
        //  switch undo over as it'll be fired from the opposite array 
        //  (i.e. if it was an undo when it was received here, we will send it to redo, 
        //  so it'll be a redo when it's next fires)
        undo: !val.undo,
        updateType: updateType,
        projectKey: state.projectKey,
        artboard: val.artboard,
        // object fields
        objectID: val.objectID,
        field: val.field,
        data: data,
        // artboard fields
        artboards: artboards,
        screenshots: screenshots,
        storyboardFields: storyboardFields,
        projectName: state.projectName,
        floorColour: state.floorColour
      }

      // handle undo logic
      // send to undo array if undo is false, as in it's not an undo action being fired
      if (val.undo === false) {
        state.undoActions.unshift(currentState)
        state.redoActions.shift();
        state.undoActions.length > 10 && state.undoActions.pop()
      }
      // send to redo array if undo is true, as in this is an undo being fired please enable redo
      else {
        state.redoActions.unshift(currentState)
        state.undoActions.shift();
        state.redoActions.length > 10 && state.redoActions.pop()
      }
      // console.log(val)
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // SEND USER PRESENCE UPDATE TO REALTIME CHANNEL
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  sendPresenceUpdateToChannel: (val) =>
    set(produce((state) => {
      // handle channel update
      const projectChannel = state.xanoClient.channel(`project/${val.projectKey}`, {
        presence: true
      });
      projectChannel.message(val, { type: 'presenceUpdate', uniqueRealtimeId: state.uniqueRealtimeId })
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // SEND USER PROJECT UPDATE TO REALTIME CHANNEL
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  sendUpdateToChannel: (val, presenceUpdate) =>
    set(produce((state) => {
      console.log(val)
      // handle channel update
      const projectChannel = state.xanoClient.channel(`project/${state.projectKey}`, {
        presence: true
      });
      projectChannel.message(val, { type: 'projectUpdate' })
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // SEND REALTIME UPDATE TO STATE
  // ~~~~~~~~~~~~~~~~~~~~~~~~  
  realtimeEventUpdate: (action) =>
    set(produce((state) => {
      console.log(action)
      // UPDATE OBJECT
      if (action.payload.updateType === "objectUpdate") {
        state.artboards[action.payload.artboard].find(
          (x) => x.id === action.payload.objectID
        )[action.payload.field] = action.payload.data
      }

      // ADD OBJECT
      else if (action.payload.updateType === "objectAdd") {
        state.artboards[action.payload.artboard].push(action.payload.data)
      }

      // REMOVE OBJECT
      else if (action.payload.updateType === "objectRemove") {
        state.artboards[action.payload.artboard].splice(
          state.artboards[action.payload.artboard].findIndex((x) => x.id === action.payload.objectID),
          1
        );
      }

      else if (action.payload.updateType === "artboardUpdate") {
         // turn screenshot array into object again
         const keyedObject = action.payload.screenshots.reduce((acc, val, index) => {
          // "index" is zero-based, so we use (index + 1)
          acc[index + 1] = val;
          return acc;
        }, {});
    

        state.artboards = action.payload.artboards
        state.storyboardFields = action.payload.storyboardFields
        state.screenshots = keyedObject
      }

      else if (action.payload.updateType === "artboardAdd") {
        // turn screenshot array into object again
        const keyedObject = action.payload.screenshots.reduce((acc, val, index) => {
          // "index" is zero-based, so we use (index + 1)
          acc[index + 1] = val;
          return acc;
        }, {});

        state.artboards = action.payload.artboards
        state.storyboardFields = action.payload.storyboardFields
        state.screenshots = keyedObject
      }

      else if (action.payload.updateType === "artboardRemove") {
         // turn screenshot array into object again
         const keyedObject = action.payload.screenshots.reduce((acc, val, index) => {
          // "index" is zero-based, so we use (index + 1)
          acc[index + 1] = val;
          return acc;
        }, {});
        
        state.artboards = action.payload.artboards
        state.storyboardFields = action.payload.storyboardFields
        state.screenshots = keyedObject
      }

      else if (action.payload.updateType === "artboardMove") {
        // turn screenshot array into object again
        const keyedObject = action.payload.screenshots.reduce((acc, val, index) => {
          // "index" is zero-based, so we use (index + 1)
          acc[index + 1] = val;
          return acc;
        }, {});
        
        state.artboards = action.payload.artboards
        state.storyboardFields = action.payload.storyboardFields
        state.screenshots = keyedObject
      }

      else if (action.payload.updateType === "title") {
        state.artboards = action.payload.artboards
        state.storyboardFields = action.payload.storyboardFields
      }

    })),
  // PASS THE FIELD BEING EDITED (E.G. NAME) TO A GENERAL EDIT PROJECT FUNCTION
  // THEN XANO FINDS THAT SPECIFIC PROJECT FIELD AND PASSES THE NEW VAL
  updateProjectName: (newVal) =>
    set(produce((state) => {
      state.projectName = newVal;
      // state.saveUserProject()
      saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })

    })),
  setFloorColour: (value) =>
    set(produce((state) => {

      state.floorColour = value
      // state.saveUserProject()
      saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })

    })),
  updateProjectUserName: (newVal) =>
    set(produce((state) => {
      state.projectUserName = newVal;
      // state.saveUserProject()
      saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })

    })),
  updateProjectUserActivity: (newVal) =>
    set(produce((state) => {
      state.projectUserActivity = newVal;
      // state.saveUserProject()
      saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })

    })),
  updateProjectUserOutcome: (newVal) =>
    set(produce((state) => {
      state.projectUserOutcome = newVal;
      // state.saveUserProject()
      saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })


    })),
  undoIndexPlus: () => set(produce((state) => {
    state.undoIndex++
  })),
  addScreenshot: (artboard, screenshot) =>{
    set(produce((state) => {
      state.screenshots[artboard] = screenshot
    }))
  // Zustand updates happen synchronously, so at this point
  // the store has already updated. We can just return
  // a resolved Promise so you can await it downstream.
  return Promise.resolve();
}
    ,
  setScreenshotStatus: (val) =>
    set(produce((state) => {

      state.screenshotStatus = val 

    })),
  addArtboardPositional: (position) =>
    set(produce((state) => {

      // CAPTURE NUMBERS OF CURRENT ARTBOARDS
      const firstHalf = Object.keys(state.artboards).slice(0, position)
      const secondHalf = Object.keys(state.artboards).slice(position)

      // SET UP OBJECTS TO BE LATER COPIED OVER AS NEW ARTBOARDS/STORYBOARD FIELDS ETC
      // Create an empty object to be used to store deep clones of all artboards/storyboardFields before the removed artboard
      let firstHalfArtboardClones = {}
      let firstHalfStoryboardFieldsClones = {}
      let firstHalfScreenshotClones = {}

      // Create an empty object to be used to store deep clones of all artboards/storyboardFields subsequent to the removed artboard
      let secondHalfArtboardClones = {}
      let secondHalfStoryboardFieldsClones = {}
      let secondHalfScreenshotClones = {}

      // CLONE DATA FROM STATE ONTO OBJECTS TO BE COPIED
      // iterate through array of subsequent artboards/storyboardFields, making a clone of each real state artboard into artboardClones (same for storyboards)
      const firstArtboardCloneMap = () => {
        firstHalf.map(x => {
          firstHalfArtboardClones[x] = _.cloneDeep(state.artboards[x])
        }
        )
      }
      const firstStoryboardFieldsCloneMap = () => {
        firstHalf.map(x => {
          firstHalfStoryboardFieldsClones[x] = _.cloneDeep(state.storyboardFields[x])
        }
        )
      }
      const firstScreenshotCloneMap = () => {
        firstHalf.map(x => {
          firstHalfScreenshotClones[x] = _.cloneDeep(state.screenshots[x])
        }
        )
      }

      // iterate through array of subsequent artboards/storyboardFields, making a clone of each real state artboard into artboardClones (same for storyboards)
      const secondArtboardCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfArtboardClones[newNumber + 1] = _.cloneDeep(state.artboards[x])
        }
        )
      }
      const secondStoryboardFieldsCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfStoryboardFieldsClones[newNumber + 1] = _.cloneDeep(state.storyboardFields[x])
        }
        )
      }
      const secondScreenshotCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfScreenshotClones[newNumber + 1] = _.cloneDeep(state.screenshots[x])
        }
        )
      }
      // ADD BLANK ARTBOARDS/STORYBOARD FIELDS ONTO END OF FIRST HALF OBJECT FOR NEW ARTBOARD
      const addNewArtboard = () => {
        let pos = parseInt(position)
        firstHalfArtboardClones[pos + 1] = []
      }
      const addNewStoryboardField = () => {
        let pos = parseInt(position)
        firstHalfStoryboardFieldsClones[pos + 1] = {
          "name": "",
          "comments": "",
          "description": ""
        }
      }
      const addNewScreenshot = () => {
        let pos = parseInt(position)
        firstHalfScreenshotClones[pos + 1] = "https://x1hz-knoc-qcpv.e2.xano.io/vault/HLo0Pmw4/ETPUCk8waC9EdweQr3ttyq5yz3o/QFye2A../file-a33c98.png"
        
      }

      const cloneFunctions = () => {
        firstArtboardCloneMap()
        firstStoryboardFieldsCloneMap()
        firstScreenshotCloneMap()
        secondArtboardCloneMap()
        secondStoryboardFieldsCloneMap()
        secondScreenshotCloneMap()
      }

      // RUN FUNCTIONS
      cloneFunctions()
      addNewArtboard()
      addNewStoryboardField()
      addNewScreenshot()

      // back when state was updated here, not sent to channel first.
      // state.artboards = {...firstHalfArtboardClones, ...secondHalfArtboardClones}
      // state.storyboardFields = {...firstHalfStoryboardFieldsClones, ...secondHalfStoryboardFieldsClones}

      // saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName,   artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      const channelUpdateArtboards = { ...firstHalfArtboardClones, ...secondHalfArtboardClones }
      const channelUpdateStoryboardFields = { ...firstHalfStoryboardFieldsClones, ...secondHalfStoryboardFieldsClones }
      const channelUpdateScreenshots = { ...firstHalfScreenshotClones, ...secondHalfScreenshotClones }

      state.artboards = channelUpdateArtboards
      state.storyboardFields = channelUpdateStoryboardFields
      state.screenshots = channelUpdateScreenshots
    
      const updateObject = { updateType: "artboardAdd", undo: false, projectKey: state.projectKey, projectName: state.projectName, artboards: channelUpdateArtboards, screenshots: Object.values(channelUpdateScreenshots), storyboardFields: channelUpdateStoryboardFields, floorColour: state.floorColour }
      state.sendUpdateToChannel(updateObject)

    })),
  //DUPLICATE ARTBOARD
  copyArtboardPositional: (position) =>
    set(produce((state) => {


      // CAPTURE NUMBERS OF CURRENT ARTBOARDS
      const firstHalf = Object.keys(state.artboards).slice(0, position)
      const secondHalf = Object.keys(state.artboards).slice(position)

      // SET UP OBJECTS TO BE LATER COPIED OVER AS NEW ARTBOARDS/STORYBOARD FIELDS ETC
      // Create an empty object to be used to store deep clones of all artboards/storyboardFields before the removed artboard
      let firstHalfArtboardClones = {}
      let firstHalfStoryboardFieldsClones = {}
      let firstHalfScreenshotClones = {}

      // Create an empty object to be used to store deep clones of all artboards/storyboardFields subsequent to the removed artboard
      let secondHalfArtboardClones = {}
      let secondHalfStoryboardFieldsClones = {}
      let secondHalfScreenshotClones = {}

      // CLONE DATA FROM STATE ONTO OBJECTS TO BE COPIED
      // iterate through array of subsequent artboards/storyboardFields, making a clone of each real state artboard into artboardClones (same for storyboards)
      const firstArtboardCloneMap = () => {
        firstHalf.map(x => {
          firstHalfArtboardClones[x] = _.cloneDeep(state.artboards[x])
        }
        )
      }
      const firstStoryboardFieldsCloneMap = () => {
        firstHalf.map(x => {
          firstHalfStoryboardFieldsClones[x] = _.cloneDeep(state.storyboardFields[x])
        }
        )
      }
      const firstScreenshotCloneMap = () => {
        firstHalf.map(x => {
          firstHalfScreenshotClones[x] = _.cloneDeep(state.screenshots[x])
        }
        )
      }

      // iterate through array of subsequent artboards/storyboardFields, making a clone of each real state artboard into artboardClones (same for storyboards)
      const secondArtboardCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfArtboardClones[newNumber + 1] = _.cloneDeep(state.artboards[x])
        }
        )
      }
      const secondStoryboardFieldsCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfStoryboardFieldsClones[newNumber + 1] = _.cloneDeep(state.storyboardFields[x])
        }
        )
      }
      const secondScreenshotCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfScreenshotClones[newNumber + 1] = _.cloneDeep(state.screenshots[x])
        }
        )
      }
      // ADD BLANK ARTBOARDS/STORYBOARD FIELDS ONTO END OF FIRST HALF OBJECT FOR NEW ARTBOARD
      const addNewArtboard = () => {
        let pos = parseInt(position)
        firstHalfArtboardClones[pos + 1] = _.cloneDeep(state.artboards[pos])
      }
      const addNewStoryboardField = () => {
        let pos = parseInt(position)
        firstHalfStoryboardFieldsClones[pos + 1] = _.cloneDeep(state.storyboardFields[pos])
      }
      const addNewScreenshot = () => {
        let pos = parseInt(position)
        firstHalfScreenshotClones[pos + 1] = _.cloneDeep(state.screenshots[pos])
        
      }

      const cloneFunctions = () => {
        firstArtboardCloneMap()
        firstStoryboardFieldsCloneMap()
        firstScreenshotCloneMap()
        secondArtboardCloneMap()
        secondStoryboardFieldsCloneMap()
        secondScreenshotCloneMap()
      }

      // RUN FUNCTIONS
      cloneFunctions()
      addNewArtboard()
      addNewStoryboardField()
      addNewScreenshot()

      // back when state was updated here, not sent to channel first.
      // state.artboards = {...firstHalfArtboardClones, ...secondHalfArtboardClones}
      // state.storyboardFields = {...firstHalfStoryboardFieldsClones, ...secondHalfStoryboardFieldsClones}

      // saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName,   artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      const channelUpdateArtboards = { ...firstHalfArtboardClones, ...secondHalfArtboardClones }
      const channelUpdateStoryboardFields = { ...firstHalfStoryboardFieldsClones, ...secondHalfStoryboardFieldsClones }
      const channelUpdateScreenshots = { ...firstHalfScreenshotClones, ...secondHalfScreenshotClones }

      state.artboards = channelUpdateArtboards
      state.storyboardFields = channelUpdateStoryboardFields
      state.screenshots = channelUpdateScreenshots

      state.sendUpdateToChannel({ updateType: "artboardAdd", projectKey: state.projectKey, projectName: state.projectName, artboards: channelUpdateArtboards, screenshots: Object.values(channelUpdateScreenshots), storyboardFields: channelUpdateStoryboardFields, floorColour: state.floorColour })

    })),
  // MOVE ARTBOARD
  moveArtboard: (source, destination) =>
    set(produce((state) => {
      const sourceName = source + 1
      const newArtboardKeys = Object.keys(state.artboards)
      newArtboardKeys.splice(source, 1)
      newArtboardKeys.splice(destination, 0, sourceName.toString())

      const newArtboards = {}
      let artboardIndex = 0
      for (const key of newArtboardKeys) {
        newArtboards[artboardIndex + 1] = _.cloneDeep(state.artboards[key]);
        artboardIndex++
      }

      const newStoryboardFields = {}
      let storyIndex = 0
      for (const key of newArtboardKeys) {
        newStoryboardFields[storyIndex + 1] = _.cloneDeep(state.storyboardFields[key]);
        storyIndex++
      }
      
      const newScreenshotFields = {}
      let screenshotIndex = 0
      for (const key of newArtboardKeys) {
        newScreenshotFields[screenshotIndex + 1] = _.cloneDeep(state.screenshots[key]);
        screenshotIndex++
      }
      // saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName,   artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })

      const channelUpdateArtboards = { ...newArtboards }
      const channelUpdateStoryboardFields = { ...newStoryboardFields }
      const channelUpdateScreenshots = { ...newScreenshotFields }

      state.artboards = channelUpdateArtboards
      state.storyboardFields = channelUpdateStoryboardFields
      state.screenshots = channelUpdateScreenshots

      state.sendUpdateToChannel({ updateType: "artboardMove", projectKey: state.projectKey, projectName: state.projectName, artboards: channelUpdateArtboards, screenshots: Object.values(channelUpdateScreenshots), storyboardFields: channelUpdateStoryboardFields, floorColour: state.floorColour })

    })),
  addArtboardTemplatePositional: (position, scene) =>
    set(produce((state) => {

      // CAPTURE NUMBERS OF CURRENT ARTBOARDS
      const firstHalf = Object.keys(state.artboards).slice(0, position)
      const secondHalf = Object.keys(state.artboards).slice(position)

      // SET UP OBJECTS TO BE LATER COPIED OVER AS NEW ARTBOARDS/STORYBOARD FIELDS ETC
      // Create an empty object to be used to store deep clones of all artboards/storyboardFields before the removed artboard
      let firstHalfArtboardClones = {}
      let firstHalfStoryboardFieldsClones = {}
      let firstHalfScreenshotClones = {}

      // Create an empty object to be used to store deep clones of all artboards/storyboardFields subsequent to the removed artboard
      let secondHalfArtboardClones = {}
      let secondHalfStoryboardFieldsClones = {}
      let secondHalfScreenshotClones = {}

      // CLONE DATA FROM STATE ONTO OBJECTS TO BE COPIED
      // iterate through array of subsequent artboards/storyboardFields, making a clone of each real state artboard into artboardClones (same for storyboards)
      const firstArtboardCloneMap = () => {
        firstHalf.map(x => {
          firstHalfArtboardClones[x] = _.cloneDeep(state.artboards[x])
        }
        )
      }
      const firstStoryboardFieldsCloneMap = () => {
        firstHalf.map(x => {
          firstHalfStoryboardFieldsClones[x] = _.cloneDeep(state.storyboardFields[x])
        }
        )
      }
      const firstScreenshotCloneMap = () => {
        firstHalf.map(x => {
          firstHalfScreenshotClones[x] = _.cloneDeep(state.screenshots[x])
        }
        )
      }

      // iterate through array of subsequent artboards/storyboardFields, making a clone of each real state artboard into artboardClones (same for storyboards)
      const secondArtboardCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfArtboardClones[newNumber + 1] = _.cloneDeep(state.artboards[x])
        }
        )
      }
      const secondStoryboardFieldsCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfStoryboardFieldsClones[newNumber + 1] = _.cloneDeep(state.storyboardFields[x])
        }
        )
      }
      const secondScreenshotCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfScreenshotClones[newNumber + 1] = _.cloneDeep(state.screenshots[x])
        }
        )
      }
      // ADD BLANK ARTBOARDS/STORYBOARD FIELDS ONTO END OF FIRST HALF OBJECT FOR NEW ARTBOARD
      const addNewArtboard = () => {
        let pos = parseInt(position)
        firstHalfArtboardClones[pos + 1] = scene.scene
      }
      const addNewStoryboardField = () => {
        let pos = parseInt(position)
        firstHalfStoryboardFieldsClones[pos + 1] = {
          "name": scene.Name,
          "comments": "",
          "description": scene.storyboard_fields.description
        }
      }
      const addNewScreenshot = () => {
        let pos = parseInt(position)
        firstHalfScreenshotClones[pos + 1] = "https://x1hz-knoc-qcpv.e2.xano.io/vault/HLo0Pmw4/ETPUCk8waC9EdweQr3ttyq5yz3o/QFye2A../file-a33c98.png"
        
      }

      const cloneFunctions = () => {
        firstArtboardCloneMap()
        firstStoryboardFieldsCloneMap()
        firstScreenshotCloneMap()
        secondArtboardCloneMap()
        secondStoryboardFieldsCloneMap()
        secondScreenshotCloneMap()
      }

      // RUN FUNCTIONS
      cloneFunctions()
      addNewArtboard()
      addNewStoryboardField()
      addNewScreenshot()
      // Add screenshots to xano

      const channelUpdateArtboards = { ...firstHalfArtboardClones, ...secondHalfArtboardClones }
      const channelUpdateStoryboardFields = { ...firstHalfStoryboardFieldsClones, ...secondHalfStoryboardFieldsClones }
      const channelUpdateScreenshots = { ...firstHalfScreenshotClones, ...secondHalfScreenshotClones }

      state.artboards = channelUpdateArtboards
      state.storyboardFields = channelUpdateStoryboardFields
      state.screenshots = channelUpdateScreenshots
    
      const updateObject = { updateType: "artboardAdd", undo: false, projectKey: state.projectKey, projectName: state.projectName, artboards: channelUpdateArtboards, screenshots: Object.values(channelUpdateScreenshots), storyboardFields: channelUpdateStoryboardFields, floorColour: state.floorColour }
      state.sendUpdateToChannel(updateObject)

    })),
  addUndoArtboard: (key, remainderArrayLength, value, storyboardValue) =>
    set(produce((state) => {

      const removeArtboardAction = {
        action: "removeArtboard",
        artboardNumber: key,
        oldArtboardData: value,
        oldStoryboardData: storyboardValue,
        remainderArrayLength: remainderArrayLength
      }

      let keysToChange = Object.keys(state.artboards).slice(state.artboards.length - (remainderArrayLength -= 1))

      let artboardClones = {}
      let storyboardFieldsClones = {}

      const artboardReplaceMap = () => {
        keysToChange.map(x => {
          let newKey = parseFloat(x)
          return state.artboards[newKey += 1] = _.cloneDeep(state.artboards[x])

        })
      }

      const storyboardFieldsReplaceMap = () => {
        keysToChange.map(x => {
          let newKey = parseFloat(x)
          return state.storyboardFields[newKey += 1] = _.cloneDeep(state.storyboardFields[x])

        })
      }

      keysToChange.length > 1 && artboardReplaceMap()
      keysToChange.length > 1 && storyboardFieldsReplaceMap()

      state.artboards[key] = [...value]
      state.storyboardFields[key] = storyboardValue
      state.redoActions.unshift(removeArtboardAction)
      state.undoActions.shift();
      state.redoActions.length > 10 && state.redoActions.pop()

      // state.saveUserProject()
      saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })

    })),
  removeArtboard: (key, undo) =>
    set(produce((state) => {

      // ~~~~~~~~~~~~~~~~~~~~~~~~
      //REMOVE ARTBOARD
      // ~~~~~~~~~~~~~~~~~~~~~~~~
      // Create an array copy of all artboard numbers AFTER the removed artboard
      let keysToChange = Object.keys(state.artboards).slice(key)
      // Create an empty object to be used to store deep clones of all artboards/storyboardFields subsequent to the removed artboard
      let artboardClones = {}
      let storyboardFieldsClones = {}
      let screenshotClones = {}

      let newArtboardState = _.cloneDeep(state.artboards)
      let newStoryboardFieldsState = _.cloneDeep(state.storyboardFields)
      let newScreenshotState = _.cloneDeep(state.screenshots)

      // iterate through array of subsequent artboards/storyboardFields, making a clone of each real state artboard into artboardClones (same for storyboards)
      const artboardCloneMap = () => {
        keysToChange.map(x => {
          artboardClones[x] = _.cloneDeep(state.artboards[x])
        }
        )
      }
      const storyboardFieldsCloneMap = () => {
        keysToChange.map(x => {
          storyboardFieldsClones[x] = _.cloneDeep(state.storyboardFields[x])
        }
        )
      }
      const screenshotCloneMap = () => {
        keysToChange.map(x => {
          screenshotClones[x] = _.cloneDeep(state.screenshots[x])
        }
        )
      }

      // Ran after the selected artboard is deleted. This iterates through the state and replaces artboards with the new true data
      // if we didn't do this, removing an artboard like '3' would create a bad line of data (artboards: 1, 2, 4, 5)
      // this lets us keep the names of the artboards concurrent (1, 2, 3, 4, 5) remove 3 => (1, 2, 3, 4)
      const artboardReplaceMap = () => {
        keysToChange.map(x => {
          // return state.artboards[x - 1] = [..._.cloneDeep(artboardClones[x])]
          return newArtboardState[x - 1] = [..._.cloneDeep(artboardClones[x])]

        })
      }
      // so we need to run keysToChange to get the artboard numbers that are being cloned
      // then we need to run artboardCloneMap to copy the artboard data for those artboards in keysToChange
      // then we need to run artboardReplaceMap to get the artboard data referenced in keysToChange 
      // and create new entries in state, naming artboards correctly (so artboard minus one if needed)
      // and copy the data from artboardClones to those artboards
      // (the same process is happening for the storyboardFields)
      // we also need to ensure the artboard is changed if you're deleting an artboard behind the current
      // so first active thing is checking if there are any artboards after the one you're deleting
      // if there are, you clone those artboards using the above process
      const storyboardFieldsReplaceMap = () => {
        keysToChange.map(x => {
          // return state.storyboardFields[x - 1] = _.cloneDeep(storyboardFieldsClones[x])
          return newStoryboardFieldsState[x - 1] = _.cloneDeep(storyboardFieldsClones[x])

        })
      }
      const screenshotReplaceMap = () => {
        keysToChange.map(x => {
          // return state.storyboardFields[x - 1] = _.cloneDeep(storyboardFieldsClones[x])
          return newScreenshotState[x - 1] = _.cloneDeep(screenshotClones[x])

        })
      }

      // if the current artboard is greater than the deleted one, it can potentially crash the app, especially if 
      // it's the final artboard that gets deleted. this function changes the current artboard
      const changeCurrentArtboard = () => {
        state.currentObjectArtboard = state.currentObjectArtboard - 1
      }
      // this checks that there are actually artboards after the deleted one, then runs the cloning function further up
      keysToChange.length > 0 && artboardCloneMap()
      keysToChange.length > 0 && storyboardFieldsCloneMap()
      keysToChange.length > 0 && screenshotCloneMap()

      // this deletes the selected arboard (commenting out to edit transient state instead and send to channel)
      delete newArtboardState[key];
      delete newStoryboardFieldsState[key];
      delete newScreenshotState[key];

      // this iterates through the array of subsequent artboards and replaces state with the right data
      keysToChange.length > 0 && artboardReplaceMap()
      keysToChange.length > 0 && storyboardFieldsReplaceMap()
      keysToChange.length > 0 && screenshotReplaceMap()
      // this fires the 'change current artboard' function if the current  artboard is greater than the deleted one
      state.currentObjectArtboard > key && changeCurrentArtboard()
      // this deletes the final artboard from state, as we don't need it, otherwise we'd have two copies of the final artboard data

      delete newArtboardState[keysToChange.slice(-1)]
      delete newStoryboardFieldsState[keysToChange.slice(-1)]
      delete newScreenshotState[keysToChange.slice(-1)]

      state.artboards = newArtboardState
      state.storyboardFields = newStoryboardFieldsState
      state.screenshots = newScreenshotState

      console.log(newScreenshotState)
      state.sendUpdateToChannel({ updateType: "artboardRemove", projectKey: state.projectKey, projectName: state.projectName, artboards: newArtboardState, screenshots: Object.values(newScreenshotState), storyboardFields: newStoryboardFieldsState, floorColour: state.floorColour })

    })),
  updateArtboard: (artboard) => {
    set(produce((state) => {
      state.currentObjectArtboard = artboard;

    }))

  },
  cycleArtboards: () => {
    set(produce((state) => {
      const artboards = Object.keys(state.artboards)
      let index = 0
      const cycleScenes = () => {
        state.currentObjectArtboard = artboards[index]; // Set the state to the current scene key
        index++; // Move to the next scene key
      }
      if (index < artboards.length) {
        setTimeout(cycleScenes, 750); // Wait 0.75 seconds before moving to the next scene
      }
    }))

  },
  updatePreviewCamera: (type, value) => {
    set(produce((state) => {
      type === 'position' ?
        state.previewCameraPosition = value
        : state.previewCameraRotation = value
    }))
  },
  updatePreviewCameraRotation: (rotation) => {
    set(produce((state) => {
      // state.previewCameraPosition = [state.artboards[artboard].find((x) => x.id === "camera").position[0], 1, state.artboards[artboard].find((x) => x.id === "camera").position[2]]
      state.previewCameraRotation = rotation
    }))
  },
  updateArtboardAndPreviewCamera: (artboard) => {
    set(produce((state) => {
      state.teleportCount = 0
      state.currentObjectArtboard = artboard;
      state.selectedObjectID = "0"
      // state.previewCameraPosition = [state.artboards[artboard].find((x) => x.id === "camera").position[0], 1, state.artboards[artboard].find((x) => x.id === "camera").position[2]]
    }))
  },
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE CONTENT OF TEXT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateStoryboardFields: (artboard, fieldType, fieldValue) =>
    set(produce((state) => {
      //change field value to new content
      state.storyboardFields[artboard][fieldType] = fieldValue;

      // state.saveUserProject()
      // saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName,   artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      state.sendUpdateToChannel({ updateType: "artboardUpdate", projectKey: state.projectKey, artboards: state.artboards, storyboardFields: state.storyboardFields })
    })),
  updateTextContent: ({ id, currentObjectArtboard, content, undo }) =>
    set(produce((state) => {
      // get current content in state before change is applied
      const stateContent = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).content
      //change state content to new content
      state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).content = content;

      // saveObjectUpdate({ projectKey: state.projectKey, artboard: currentObjectArtboard, objectID: id, field: "content", data: content })
      state.sendUpdateToChannel({ updateType: "objectUpdate", projectKey: state.projectKey, artboard: currentObjectArtboard, objectID: id, field: "content", data: content })
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  //ADD TEXT OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  addTextObject: ({ id, currentObjectArtboard, textKind, content, isLocked, colour, bGColour, position, rotation, scale, undo, matrix }) =>
    set(produce((state) => {

      const newObject = {
        id: id,
        // id: shortid.generate(),
        object: textKind,
        category: "text",
        content: content,
        position: [0, 0, 0],
        rotation: [0, 0, 0, 0],
        matrixState: new THREE.Matrix4(),
        scale: [1, 1, 1],
        destination: "",
        objectTitle: content,
        colour: "black",
        bGColour: " ",
        pose: {
          name: content,
          modelPath: null
        }
      }

      state.sendUpdateToChannel({ updateType: "objectAdd", projectKey: state.projectKey, artboard: currentObjectArtboard, data: newObject })
      // saveObjectAdd({ projectKey: state.projectKey, artboard: currentObjectArtboard, object: newObject})  

    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  //COPY OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  copyObject: ({ currentObjectArtboard, id, newID, undo }) =>
    set(produce((state) => {
      const duplicateObject = state.artboards[currentObjectArtboard].find((x) => x.id === id)
      // const newID = shortid.generate()

      const newObject = {
        id: newID,
        object: duplicateObject.object,
        category: duplicateObject.category,
        content: duplicateObject.content,
        position: duplicateObject.position,
        rotation: duplicateObject.rotation,
        matrixState: duplicateObject.matrixState,
        scale: duplicateObject.scale,
        pose: duplicateObject.pose,
        destination: "",
        colour: duplicateObject.colour,
        objectTitle: `${duplicateObject.objectTitle} copy`,
        image: duplicateObject.image,
        imageSize: duplicateObject.imageSize,
        imageTexture: duplicateObject.imageTexture,
        isLocked: false,
        curved: duplicateObject.curved,
        curveAmount: duplicateObject.curveAmount,
        bGColour: duplicateObject.bGColour,
        figmaID: duplicateObject.figmaID,
        figmaURL: duplicateObject.figmaURL,
        figmaSize: duplicateObject.figmaSize
      }
      state.sendUpdateToChannel({ updateType: "objectAdd", projectKey: state.projectKey, artboard: currentObjectArtboard, data: newObject })
      // saveObjectAdd({ projectKey: state.projectKey, artboard: currentObjectArtboard, object: newObject})  

    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  //ADD OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  addObject: ({ currentObjectArtboard, id, undo, category, object, position, scale, rotation, matrix, colour, pose, image, imageSize, imageTexture, aiKey, figmaID, figmaURL, figmaSize, uploadedURL, isLocked, copiedUndo, curved, curveAmount, bGColour, uploadId, databaseURL, databaseId }) =>
    set(produce((state) => {
      const newObject = {
        // id: shortid.generate(),
        id: id,
        object: object,
        category: category,
        content: false,
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Euler(),
        matrixState: new THREE.Matrix4(),
        scale: [1, 1, 1],
        destination: "",
        colour: "white",
        objectTitle: object,
        figmaID: figmaID,
        figmaURL: figmaURL,
        figmaSize: figmaSize,
        imageTexture: imageTexture,
        image: image,
        imageSize: imageSize,
        aiKey: aiKey,
        curved: false,
        curveAmount: 3,
        pose: {
          name: pose.name,
          modelPath: pose.modelPath
        },
        bGColour: bGColour,
        isLocked: false,
        uploadedURL: uploadedURL,
        uploadId: uploadId,
        databaseURL: databaseURL, 
        databaseId: databaseId
        // modelPath: modelPath
      }
      state.sendUpdateToChannel({ updateType: "objectAdd", projectKey: state.projectKey, artboard: currentObjectArtboard, data: newObject })
      // saveObjectAdd({ projectKey: state.projectKey, artboard: currentObjectArtboard, object: newObject})  
      // saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName,   artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })

    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // REMOVE OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  removeObject: ({ currentObjectArtboard, id, undo, category, content, textKind, object, position, scale, rotation, colour, matrix, copiedUndo, image, imageSize, imageTexture, curved, curveAmount, pose, uploadedURL, bGColour }) =>
    set(produce((state) => {
      // state.artboards[currentObjectArtboard].splice(
      //   state.artboards[currentObjectArtboard].findIndex((x) => x.id === id),
      //   1 
      // );
      state.sendUpdateToChannel({ updateType: "objectRemove", projectKey: state.projectKey, artboard: currentObjectArtboard, objectID: id, undo: false })
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE MATRIX OF OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateObjectMatrix: ({ id, currentObjectArtboard, matrix, undo }) => {
    set(produce((state) => {

      const stateArrayMatrix = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).matrixState

      const updateObject = {
        updateType: "objectUpdate", undo: undo, projectKey: state.projectKey, artboard: currentObjectArtboard, objectID: id, field: "matrixState", data: matrix,
        undoActions: state.undoActions
      }

      //check if new/old position are same 
      if (Array.isArray(stateArrayMatrix) &&
        Array.isArray(matrix) &&
        stateArrayMatrix.length === matrix.length &&
        stateArrayMatrix.every((val, index) => val === matrix[index])) {
      }
      else {
        state.sendUpdateToChannel(updateObject)
        state.artboards[currentObjectArtboard].find(
          (x) => x.id === id
        ).matrixState = new THREE.Matrix4().copy(matrix)
      }

    }))
  },
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE POSITION OF OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // ADD AN 'UPDATE OBJECT' SAVE API FUNCTION THAT FINDS THE OBJECT BY ID IN XANO
  // THEN ADD THE ENTIRE NEW OBJECT OBJECT INCLUDING ALL NEW STUFF (POSITION, COLOUR OR OTHERWISE)
  updateObjectPosition: ({ id, currentObjectArtboard, position, undo }) =>
    set(produce((state) => {


      // get current position in state before change is applied
      const stateArrayPosition = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).position
      //check if new/old position are same
      if (Array.isArray(stateArrayPosition) &&
        Array.isArray(position) &&
        stateArrayPosition.length === position.length &&
        stateArrayPosition.every((val, index) => val === position[index])) {
      }
      //if not equal, change position and add to newUndoActions
      //change state position to new position sent from model component
      else {
        state.artboards[currentObjectArtboard].find(
          (x) => x.id === id
        ).position = position;
        // state.saveUserProject()
        // saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName,   artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })

      }
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE ROTATION OF OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateObjectRotation: ({ id, currentObjectArtboard, rotation, undo }) =>
    set(produce((state) => {
      // console.log(rotation)
      // capture rotation before change
      const stateArrayRotation = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).rotation
      //check if new/old rotation are same
      if (Array.isArray(stateArrayRotation) &&
        Array.isArray(rotation) &&
        stateArrayRotation.length === [rotation.x, rotation.y, rotation.z].length &&
        stateArrayRotation.every((val, index) => val === [rotation.x, rotation.y, rotation.z][index])) {

      }
      //if not equal, change rotation
      else {
        state.artboards[currentObjectArtboard].find(
          (x) => x.id === id
        ).rotation = rotation
        // state.saveUserProject()
        saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      }
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE SCALE OF OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateObjectScale: ({ id, currentObjectArtboard, scale, undo }) =>
    set(produce((state) => {
      // capture scale before change
      const stateArrayScale = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).scale
      //check if new/old scale are same
      if (Array.isArray(stateArrayScale) &&
        Array.isArray(scale) &&
        stateArrayScale.length === scale.length &&
        stateArrayScale.every((val, index) => val === scale[index])) {

      }
      //if not equal, change scale
      else {
        state.artboards[currentObjectArtboard].find(
          (x) => x.id === id
        ).scale = scale;
        // state.saveUserProject()
        saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })

        //record action including the old state
        const scaleAction = {
          action: "updateObjectScale",
          objectId: id,
          objectArtboard: currentObjectArtboard,
          oldValue: [...stateArrayScale],
          newValue: [...scale]
        }
        // send to undo array if undo is false, as in it's not an undo action being fired
        if (undo === false) {
          state.undoActions.unshift(scaleAction)
          state.redoActions.shift();
          state.undoActions.length > 10 && state.undoActions.pop()

        }
        // send to redo array if undo is true, as in this is an undo being fired please enable redo
        else {
          state.redoActions.unshift(scaleAction)
          state.undoActions.shift();
          state.redoActions.length > 10 && state.redoActions.pop()

        }
      }
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE CLICK DESTINATION OF OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateDestination: ({ id, currentObjectArtboard, destination, undo }) =>
    set(produce((state) => {
      state.sendUpdateToChannel({ updateType: "objectUpdate", projectKey: state.projectKey, artboard: currentObjectArtboard, objectID: id, field: "destination", data: destination, undo: false })
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE ISLOCKED OF OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateIsLocked: ({ id, undo }) =>
    set(produce((state) => {
      // capture destination before change
      const stateIsLocked = state.artboards[state.currentObjectArtboard].find(
        (x) => x.id === id
      ).isLocked
      state.sendUpdateToChannel({ updateType: "objectUpdate", projectKey: state.projectKey, artboard: state.currentObjectArtboard, objectID: id, field: "isLocked", data: !stateIsLocked })
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE CURVED OF OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateCurved: ({ id, undo }) =>
    set(produce((state) => {
      // capture destination before change
      const stateCurved = state.artboards[state.currentObjectArtboard].find(
        (x) => x.id === id
      ).curved
      state.sendUpdateToChannel({ updateType: "objectUpdate", projectKey: state.projectKey, artboard: state.currentObjectArtboard, objectID: id, field: "curved", data: !stateCurved })
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE CURVEAMOUNT OF OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateCurveAmount: ({ id, val, undo }) =>
    set(produce((state) => {
      state.sendUpdateToChannel({ updateType: "objectUpdate", projectKey: state.projectKey, artboard: state.currentObjectArtboard, objectID: id, field: "curveAmount", data: val })

    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE COLOUR OF SHAPE OBJECT 
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateShapeColour: ({ id, currentObjectArtboard, colour, undo }) =>
    set(produce((state) => {
      state.sendUpdateToChannel({ updateType: "objectUpdate", projectKey: state.projectKey, artboard: state.currentObjectArtboard, objectID: id, field: "colour", data: colour })
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE COLOUR OF MODEL OBJECT 
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateModelColour: ({ id, currentObjectArtboard, colour, undo }) =>
    set(produce((state) => {
      state.sendUpdateToChannel({ updateType: "objectUpdate", projectKey: state.projectKey, artboard: state.currentObjectArtboard, objectID: id, field: "colour", data: colour })
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE BACKGROUND COLOUR OF TEXT OBJECT 
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateTextBackgroundColour: ({ id, currentObjectArtboard, bGColour, undo }) =>
    set(produce((state) => {
      state.sendUpdateToChannel({ updateType: "objectUpdate", projectKey: state.projectKey, artboard: state.currentObjectArtboard, objectID: id, field: "bGColour", data: bGColour })
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE URL OF FIGMA OBJECT 
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateFigmaURL: ({ id, currentObjectArtboard, figmaID, undo }) =>
    updateFigmaFromID(figmaID).then((res) =>
      set(produce((state) => {
        state.sendUpdateToChannel({ updateType: "objectUpdate", projectKey: state.projectKey, artboard: state.currentObjectArtboard, objectID: id, field: "figmaURL", data: res })
      }))),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE POSE OF OBJECT 
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updatePose: ({ id, currentObjectArtboard, pose, modelPath, undo }) =>
    set(produce((state) => {
      state.sendUpdateToChannel({ updateType: "objectUpdate", projectKey: state.projectKey, artboard: state.currentObjectArtboard, objectID: id, field: "pose", data: pose })
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE TITLE OF OBJECT 
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateObjectTitle: (id, currentObjectArtboard, title, undo) =>
    set(produce((state) => {
      state.sendUpdateToChannel({ updateType: "objectUpdate", projectKey: state.projectKey, artboard: state.currentObjectArtboard, objectID: id, field: "objectTitle", data: title })
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE IMAGE TEXTURE FOR SHAPE OBJECT 
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateImageTexture: (id, currentObjectArtboard, val, undo) =>
    set(produce((state) => {
      state.sendUpdateToChannel({ updateType: "objectUpdate", projectKey: state.projectKey, artboard: state.currentObjectArtboard, objectID: id, field: "imageTexture", data: val })
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE IMAGE OBJECT URL
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateImageObjectURL: (id, currentObjectArtboard, image, imageSize, undo) =>
    set(produce((state) => {
      // capture texture before change

      state.artboards[state.currentObjectArtboard].find(
        (x) => x.id === state.selectedObjectID
      ).image = image;
      state.artboards[state.currentObjectArtboard].find(
        (x) => x.id === state.selectedObjectID
      ).imageSize = imageSize;

      saveUserProjectAPI({ projectKey: state.projectKey, projectName: state.projectName, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
    }))
}),
  // { client,
  //   presenceMapping: {
  //     cursor: true,
  //     preview: true,
  //     headset: true,
  //   },
  //   storageMapping: {
  //     projectName: true,
  //     artboards: true,
  //     storyboardFields: true,
  //   }
  // }
)




export const bringToArtboard = (artboard) => {
  console.log('come to artboard')
}
