import React, { useEffect, useState, Suspense, useRef } from "react";

import { Link, useParams } from "react-router-dom";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Modal from '@mui/material/Modal';

import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { saveAs } from "file-saver";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { OBJExporter } from "three/examples/jsm/exporters/OBJExporter";
import { useObjectStore } from "../zustand/objects";
import { getSpecificUserProjectGuestAccessAPI } from "../api";
import AppNavBar from "../components/AppNavBar";
import StoryboardTextField from "../components/StoryboardTextField"
import DownloaderFile from "../components/DownloaderFile";
import { Screenshot, ScreenshotStoryboardPOV } from "../components/Screenshot";
import Tooltip from '@mui/material/Tooltip';
import ModelAttributions from "../ModelAttributions.json";
import ViewAttribution from "../components/ViewAttribution";

import { View } from "@react-three/drei";
import { Canvas, useThree, useFrame } from "@react-three/fiber";

import { Card, Button,  Image, Input, Textarea, Text, Group, Center, Loader } from '@mantine/core';


export default function EmbedStoryboardPage() {
  const { currentObjectArtboard, updateArtboardAndPreviewCamera, getAIModels, objectsAreLoaded, exportData, artboards, getSpecificUserProject, getSpecificUserProjectGuest, projectName, loadObjects, unloadObjects, screenshots } = useObjectStore();

  const { projectKey } = useParams();

  const [open, setOpen] = useState(false);

  const handleClose = () => { 
    setOpen(false);
  }


  async function unloadProject() {
    unloadObjects()
  }   

  const [guest, setGuest] = useState(false)

  useEffect(() => {
    unloadProject()
        .then(() => getSpecificUserProjectGuestAccessAPI(projectKey))
        .then((response) => {
            if (response === true) {
                return getSpecificUserProjectGuest(projectKey)
                    .then(() => setGuest(true))
                    .then(() => getAIModels())
                    .then(() => loadObjects())
                    .then(() => updateArtboardAndPreviewCamera(1));
            } else {
                setGuest(false);
                return Promise.resolve(); // Ensure a valid chain end
            }
        });
}, []);


  const GridBackground = () => {
    return (
      <div style={{ position: "fixed", left: 0, top: 0, bottom: 0, zIndex: -1, width: "100%", height: "120%", backgroundImage: 'url("/image/background-grid.png")', backgroundAttachment: "fixed" }}></div>

    )
  } 



  

  return (<>
    <Suspense fallback={<h1>Loading...</h1>}>
      <CssBaseline />
      <GridBackground />


      <Box
        sx={{
          pt: 8,
          pl: 0
        }}
      >
        <Container sx={{ ml: 7, pl: 0 }}>
         

          <Grid container sx={{ pl: 0, mb: 2 }}>
            <Grid item xs={12} md={7} sx={{ pl: 0 }}>
              <Typography
                component="h1"
                variant="h4"
                fontWeight="700"
                color="text.primary"
                gutterBottom
                sx={{ pl: 0 }}
              >
                {guest ? "View prototype storyboard" : "This project is private"} 
              </Typography>
            </Grid>
            
            <Grid item xs={12} md={5} sx={{ pl: 0 }}>
              <Stack
                sx={{ pt: 1, display: 'flex', justifyContent: 'flex-end' }}
                direction="row"
                spacing={2}
              >
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box> 
 
      <Container sx={{ pb: 0, pt: 2, ml: 7, pl: 0 }} >
        <Grid container spacing={4}>
        
          {artboards && objectsAreLoaded && Object.keys(artboards).map((mapped, i) => (
            <Suspense key={i} fallback={<h1>Loading...</h1>}>
              <Grid item  xs={12} sm={6} md={3}>
              <Card radius={16} padding={0} style={{margin:10}}
                                        shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)">
                  <div>
                    {
                      screenshots[mapped] ? <div
                      style={{
                        width: 300, height: 200,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url(${screenshots[mapped]})`,
                      }}
                      size="small" /> :
                      <div
                        style={{
                          width: 260, height: 100,
                          borderRadius: 0, backgroundColor: '#C1C2C5'
                          // width: 130, height: 85, borderRadius: 0, backgroundColor: '#C1C2C5'
                        }}
                        size="small" />
                    }
                  </div>
                <CardContent sx={{ flexGrow: 1, pb:0 }}>
                  <Typography gutterBottom variant="h6" component="h6">
                    Scene: {mapped}
                  </Typography>
                  <Tooltip title="This is preview mode. Scene info is written in edit mode." placement="bottom-start">
                      <span>
                        <StoryboardTextField fieldType="name" isEditable={false} artboard={mapped} />
                      </span></Tooltip>
                    <Tooltip title="This is preview mode. Scene info is written in edit mode." placement="bottom-start">
                      <span><StoryboardTextField fieldType="description" isEditable={false} artboard={mapped} />
                      </span></Tooltip>
                    <StoryboardTextField fieldType="comments" isEditable={true} artboard={mapped} />
                </CardContent>
                <Group style={{margin:10}}>
                  <Button
                    onClick={() => unloadObjects()}
                    style={{
                      width: '45%', inline: true, backgroundColor: 'black', borderRadius: 32
                    }}
                    component='a'
                    href={`/share/${projectKey}?artboard=${mapped}`}>
                    View</Button>
                </Group>
              </Card>
              </Grid>
              </Suspense>
          ))} 
        </Grid>
      </Container>
    </Suspense>
  </>
  );
}
